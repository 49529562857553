<template>
  <div class="limitBox">
    <div v-for="(item,index) in props.data" :key="index" class="ImageItem">
      <div>
        <el-image
            :src="item.image"
            fit="cover"
            style="width: 100%;height: 200px">
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
      <div class="Text cursor" @click="GoToLianJie(item)">
        <p>{{ item.nameChina }}</p>
        <p>{{ item.nameEnglish }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import * as Vue from "vue";
import router from "@/router";

export default {
  name: "ImageListComp",
  props: ['data'],
  components: {},
  setup(props, {attrs, slots, emit}) {
    const store = useStore()
    const state = Vue.reactive({})

    const GoToLianJie=(item)=>{
      if(item.link!==''){
        window.open(item.link)
      }
    }
    return {
      state,
      props,
      GoToLianJie
    }
  }
}
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.limitBox{
  display: flex;
  flex-wrap: wrap ;
  .ImageItem{
    width: calc(100%/5);
    position: relative;
    .Text{
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 10;
      width: 100%;
      height: 60px;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(10px);
      text-align: center;
      p:nth-child(1){
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 5px;
      }
      p:nth-child(2){
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>