<template>
  <bg-page>
    <div class="Box">
      <div class="limitBox">
        <div class="ASEANBox">
          <div>
            <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
          </div>
          <div class="Content"><p v-html="state.content"></p></div>
        </div>
        <div class="ImageBox">
          <ImageListComp :data="state.ImageList"></ImageListComp>
        </div>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import ImageListComp from "@/views/ASEAN/Components/ImageListComp";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
export default {
  name: "ASEAN",
  components: { ImageListComp, FirstTitle },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "东盟文化",
        EnglishName: "ASEAN",
      },
      content:
        "      东盟成为东南亚地区以经济合作为基础的政治、经济、安全一体化合作组织，并建立起一系列合作机制。\n" +
        "      东盟的宗旨和目标是本着平等与合作精神，共同促进本地区经济增长、社会进步和文化发展，为建立一个繁荣、和平的东南亚国家共同体奠定基础，以促进本地区的和平与稳定。\n" +
        "     东盟成立之初只是个保卫自己安全利益及与西方保持战略关系的联盟，其活动仅限于探讨经济、文化等方面的合作。1976年2月，第一次东盟首脑会议在印尼巴厘岛举行，会议签署了《东南亚友好合作条约》以及强调东盟各国协调一致的《巴厘宣言》。\n" +
        "      此后，东盟各国加强了政治、经济和军事领域的合作，并采取了切实可行的经济发展战略，推动经济迅速增长，逐步成为一个有一定影响的区域性组织。",
      ImageList: [
        {
          id: 1,
          image: require("../../comment/image/Travel/01.jpg"),
          nameChina: "马来西亚",
          nameEnglish: "Malaysia",
          link: "http://www.cafta.org.cn/list.php?catid=130",
        },
        {
          id: 2,
          image: require("../../comment/image/Travel/02.jpg"),
          nameChina: "印度尼西亚",
          nameEnglish: "Republic of Indonesia",
          link: "http://www.cafta.org.cn/list.php?catid=126",
        },
        {
          id: 3,
          image: require("../../comment/image/Travel/03.jpg"),
          nameChina: "泰国",
          nameEnglish: "The Kingdom of Thailand",
          link: "http://www.cafta.org.cn/list.php?catid=129",
        },
        {
          id: 4,
          image: require("../../comment/image/Travel/04.jpg"),
          nameChina: "菲律宾",
          nameEnglish: "Republic of the Philippines",
          link: "http://www.cafta.org.cn/list.php?catid=125",
        },
        {
          id: 5,
          image: require("../../comment/image/Travel/05.jpg"),
          nameChina: "新加坡",
          nameEnglish: "Republic of Singapore",
          link: "http://www.cafta.org.cn/list.php?catid=127",
        },
        {
          id: 6,
          image: require("../../comment/image/Travel/06.jpg"),
          nameChina: "文莱",
          nameEnglish: "Negara Brunei Darussalam",
          link: "http://www.cafta.org.cn/list.php?catid=113",
        },
        {
          id: 7,
          image: require("../../comment/image/Travel/07.jpg"),
          nameChina: "越南",
          nameEnglish: "Socialist Republic of Vietnam",
          link: "http://www.cafta.org.cn/list.php?catid=131",
        },
        {
          id: 8,
          image: require("../../comment/image/Travel/08.jpg"),
          nameChina: "老挝",
          nameEnglish: "The Lao People’s Democratic Republic",
          link: "http://www.cafta.org.cn/list.php?catid=128",
        },
        {
          id: 9,
          image: require("../../comment/image/Travel/09.jpg"),
          nameChina: "缅甸",
          nameEnglish: "The Republic of the Union of Myanmar",
          link: "http://www.cafta.org.cn/list.php?catid=114",
        },
        {
          id: 10,
          image: require("../../comment/image/Travel/10.jpg"),
          nameChina: "柬埔寨",
          nameEnglish: "The Kingdom of Cambodia",
          link: "http://www.cafta.org.cn/list.php?catid=124",
        },
      ],
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  //padding-bottom: 40px;
  .ASEANBox {
    padding-bottom: 40px;
    .Content {
      margin-top: 20px;
      padding-bottom: 40px;
      border-bottom: 5px solid #7f0404;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-indent: 2em;
      }
    }
  }
  .ImageBox {
    padding-bottom: 40px;
    border-bottom: 5px solid #7f0404;
  }
}
</style>